<!-- Main template -->
<ng-container *ngIf="configuration.display">
    <div class="list-details-container" [class.is-header-fixed]="isFixed" *ngIf="!isLoading; else loadingTemplate">
        <!-- Sticky header -->
        <div class="list-details-header">
            <ul class="single-line" #listDetailsScrollable>
                <ng-container *ngFor="let container of containers; let i = index">
                    <ng-container [ngSwitch]="container.type">
                        <ng-container *ngSwitchCase="'downloads'">
                            <li *ngIf="container.config?.flyer?.show || (container.config?.files?.show && data?.files && data.files.length > 0)"
                                class="is-font-sm is-color-primary-on-active is-border-color-primary-on-active"
                                [class.active]="i === containerActive"
                                (click)="onContainerNavigate( i, container.key )">
                                {{ container.label }}
                            </li>
                        </ng-container>
                        <li *ngSwitchDefault
                            class="is-font-sm is-color-primary-on-active is-border-color-primary-on-active"
                            [class.active]="i === containerActive"
                            (click)="onContainerNavigate( i, container.key )">
                            {{ container.label }}
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>

        <!-- Detailed items -->
        <div class="list-details-content">
            <ng-container *ngFor="let container of containers">
                <ng-container [ngSwitch]="container.type">
                    <ng-container *ngSwitchCase="'downloads'">
                        <div *ngIf="container.config?.flyer?.show || (container.config?.files?.show && data?.files && data.files.length > 0)"
                            [id]="container.key"
                            class="list-details-container">
                            <h2 class="is-font-h2 is-font-bold mt-0"> {{ container.label }}</h2>
                            <button *ngIf="container.config?.flyer?.show"
                                    class="ras-btn-link ras-download-flyer is-color-primary"
                                    rasProposal
                                    [branchPhone]="data?.branch_phone"
                                    [equipmentId]="data?.equipment_number">
                                <fa-icon *ngIf="container.config?.flyer?.icon" [icon]="['far', container.config.flyer.icon]"></fa-icon>
                                {{ container.config?.flyer?.label }}
                            </button>
                            <div *ngIf="container.config?.files?.show && data?.files && data.files.length > 0">
                                <div *ngFor="let file of data.files" class="download-link-container">
                                    <a
                                        class="ras-download-flyer is-color-primary"
                                        target="_blank"
                                        [href]="file.url">
                                        <fa-icon *ngIf="file.type !== 'pdf'" [icon]="['far', 'file']"></fa-icon>
                                        <fa-icon *ngIf="file.type === 'pdf'" [icon]="['far', 'file-pdf']"></fa-icon>
                                        {{ file.name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngSwitchCase="'modal-link'"
                        [id]="container.key"
                        class="list-details-container">
                        <h2 class="is-font-h2 is-font-bold mt-0"> {{ container.label }}</h2>
                        <ras-modal-link [configuration]="container.config"></ras-modal-link>
                    </div>
                    <div *ngSwitchDefault
                        class="list-details-container"
                        ras-list-details-item
                        [id]="container.key"
                        [data]="container">
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>

    <!-- Loading template -->
    <ng-template #loadingTemplate>
        <div class="list-details-container is-loading">
            <div class="list-details-header">
                <ul class="single-line" >
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div class="list-details-content">
                <div class="list-details-container">
                    <p class="mt-0"></p>
                    <div></div>
                </div>
            </div>
        </div>
    </ng-template>
</ng-container>